import React, { useContext, useState } from "react";
import { Context } from "../Store";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SiteMenu } from "../menu";
import "./sidebar.css";
import { Fragment } from "react/cjs/react.production.min";
import { useMediaQuery } from "../useMedia";

function Sidebar() {
  const isXl = useMediaQuery("xl");
  const [state] = useContext(Context);
  const [open, setOpen] = useState(isXl);

  const toggleSidebar = () => {
    const value = !open;
    setOpen(value);

    // const texts = document.getElementsByClassName("sidebar-text");
    // for (let i = 0; i < texts.length; ++i) {
    //     texts[i].style.visibility = value ? "visible" : "hidden";
    // }
  };

  // const sidebarWidth = () => (open ? "240px" : "3.5rem");

  return (
    <div
      className="sidebar-container"
      style={{ width: open || isXl ? "240px" : "0", marginTop: "0.6rem" }}
    >
      <Toggler open={open || isXl} toggle={toggleSidebar} />
      <button className="closebtn" onClick={toggleSidebar}>
        &times;
      </button>
      <ul className="sidebar">
        {SiteMenu.map((m) => {
          return (
            <Fragment key={m.name}>
              <Header name={m.name} icon={m.icon} />
              <ul className="sidebar-section">
                {m.entries.map((i) => {
                  const groupsMatch = () => {
                    if (!state.groups || !i.groups || i.groups.length == 0)
                      return true;
                    for (let g = 0; g < i.groups.length; ++g)
                      if (state.groups.indexOf(i.groups[g]) !== -1) return true;
                    return false;
                  };
                  return (
                    (!i.adminOnly || state.is_admin) &&
                    groupsMatch() && (
                      <Entry
                        key={m.name + i.name}
                        name={i.name}
                        icon={i.icon}
                        path={i.path}
                        nohighlight={i.nohighlight}
                        isExternal={i.external}
                        toggle={toggleSidebar}
                      />
                    )
                  );
                })}
              </ul>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
}

const Toggler = ({ open, toggle }) => {
  return (
    <button className="sidebar-toggler" onClick={toggle}>
      {open ? "<" : ">"}
    </button>
  );
};

const Header = ({ name, icon }) => {
  return (
    <li className="sidebar-header">
      <FontAwesomeIcon icon={icon} size="2x" />
      <span className="sidebar-text"> {name}</span>
    </li>
  );
};

const Entry = ({
  name,
  icon,
  path,
  nohighlight = false,
  isExternal = false,
  toggle = () => {},
}) => {
  const pathname = useLocation().pathname;
  return (
    <li
      className={
        "sidebar-link " + (!nohighlight && pathname === path ? "selected" : "")
      }
    >
      {isExternal && (
        <a href={path} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={icon} />
          <span className="sidebar-text"> {name}</span>
        </a>
      )}
      {!isExternal && (
        <Link to={path} onClick={toggle}>
          <FontAwesomeIcon icon={icon} />
          <span className="sidebar-text"> {name}</span>
        </Link>
      )}
    </li>
  );
};

export default Sidebar;
