import { useContext } from "react";
import { Context } from "./Store";
import { config } from "./Config";
import { ADD_ALERT, DELETE_ALERT, REFRESH_TOKENS } from "./Reducer";
import useAuth from "./useAuth";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

let token_semaphore = 0;
let last_known_token = null;

const initCachedToken = (state) => {
  if (!last_known_token) last_known_token = state?.access_token;
};

export const useApiCall = (endpoint, method = "POST") => {
  const auth = useAuth();
  const [state, dispatch] = useContext(Context);
  initCachedToken(state);
  return async (body, endpoint_subpath = "", init = {}) => {
    let headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    if (last_known_token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${last_known_token}`,
      };
    }
    return await doApiCall(
      auth,
      state,
      dispatch,
      endpoint + endpoint_subpath,
      method,
      headers,
      body,
      init,
    );
  };
};

export const apiAuthentication = async (
  username,
  password,
  rememberme,
  init = {},
) => {
  return fetch(config.API_URL + "/auth", {
    ...init,
    mode: "cors",
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      rememberme: rememberme,
    }),
  }).then((resp) => {
    if (!resp?.ok) {
      last_known_token = null;
      return { error: "Authorization failed" };
    }
    last_known_token = resp?.access_token;
    return resp.json();
  });
};

export const useApiUpload = (endpoint, init = {}) => {
  const auth = useAuth();
  const [state, dispatch] = useContext(Context);
  initCachedToken(state);
  return async (formData) => {
    let headers = {
      Accept: "application/json, text/plain, */*",
    };
    if (last_known_token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${last_known_token}`,
      };
    }
    return await doApiCall(
      auth,
      state,
      dispatch,
      endpoint,
      "POST",
      headers,
      formData,
      init,
    );
  };
};

export const useApiDownload = (endpoint, method = "POST", init = {}) => {
  const [state] = useContext(Context);
  initCachedToken(state);
  return async (extra_endpoint = "") => {
    let headers = {
      Accept: "*/*",
    };
    if (last_known_token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${last_known_token}`,
      };
    }
    return await fetch(config.API_URL + endpoint + extra_endpoint, {
      ...init,
      method: method,
      headers: headers,
    });
  };
};

const waitUntilAuthorized = async (abortval) => {
  // return a promise which will return the refreshed tokens
  // once they 're available
  let promise = new Promise((resolve, reject) => {
    const wait = () => {
      if (last_known_token) {
        // refresh done, notify
        // console.log("resolved: ", last_known_token);
        resolve(last_known_token);
      } else if (token_semaphore < 1) {
        console.log("tokens refresh rejected");
        reject(abortval);
      } else {
        setTimeout(wait, 100);
      }
    };
    setTimeout(wait, 100);
  });
  return promise;
};

// returns new access token if refreshed
const refreshTokensIfNeeded = async (auth, state, dispatch) => {
  initCachedToken(state);
  const token = last_known_token ? jwt_decode(last_known_token) : null;
  const diff = token ? dayjs.unix(token?.exp).diff(dayjs(), "second") : 0;
  const isExpired = diff < 60; // last minute
  if (isExpired) {
    last_known_token = null;
    // is a previous call attempting to refresh the tokens?
    if (token_semaphore > 0) {
      // console.log("tokens refresh in progress");
      return await waitUntilAuthorized(last_known_token);
    }
    token_semaphore += 1;
    console.log("auth updating");
    dispatch({
      type: ADD_ALERT,
      payload: {
        id: "refresh_tokens",
        message: "Γίνεται ανανέωση πιστοποίησης...",
        color: "warning",
        timeout: 0,
      },
    });

    let resp = null;
    try {
      resp = await refreshTokens();
    } catch (reason) {
      console.log(reason);
      last_known_token = null;
      token_semaphore = -1;
      auth.signoutRedirect().then(() => auth.removeUser());
    }

    if (resp?.access_token) {
      console.log("auth updated");
      last_known_token = resp?.access_token;
      token_semaphore -= 1;
    } else {
      console.log("token refresh failed", resp);
      last_known_token = null;
      token_semaphore -= 1;
      auth.signoutRedirect().then(() => auth.removeUser());
    }

    dispatch({ type: DELETE_ALERT, payload: "refresh_tokens" });
    dispatch({
      type: REFRESH_TOKENS,
      payload: {
        access_token: last_known_token,
      },
    });
  }
  return last_known_token;
};

const doApiCall = async (
  auth,
  state,
  dispatch,
  endpoint,
  method,
  headers,
  body,
  init = {},
) => {
  let newToken = "";
  if (!init.skipReauth && state.access_token) {
    try {
      newToken = await refreshTokensIfNeeded(auth, state, dispatch);
      // console.log("receive token", newToken)
    } catch (err) {
      newToken = last_known_token;
      console.log(err);
    }
  }

  if (newToken) {
    // update autorization header!
    headers = {
      ...headers,
      Authorization: `Bearer ${newToken}`,
    };
  }

  return await fetch(config.API_URL + endpoint, {
    ...init,
    method: method,
    body: body,
    headers: headers,
  })
    .then((resp) => resp.json())
    // .then((resp) => {
    //     if (resp.status >= 400) {
    //         dispatch({
    //             type: SET_ERROR,
    //             payload: resp.message ? resp.message : "Κάτι πήγε λάθος...",
    //         });
    //     }
    //     return resp;
    // })
    .then((resp) => {
      if (resp) {
        // console.log("USEAPICALL", resp);
        if (resp.message && resp.message !== "") {
          if (!checkAuthenticationResponse(resp)) {
            console.log("unauthorized");
            // dispatch({ type: LOGOUT });
            // if access token is not set, do not recurse, just return the error
            if (last_known_token) {
              return doApiCall(
                auth,
                state,
                dispatch,
                endpoint,
                method,
                headers,
                body,
              );
            }
            return "";
          } else {
            // dispatch({ type: LOGOUT });
            console.log("message:", resp.message);
            // return "";
          }
        }
        return resp;
      }
    })
    .catch(() => {
      // console.log(endpoint, reason);
    });
};

const refreshTokens = async () => {
  // console.log("attempting token refresh");
  return await fetch(config.API_URL + "/refresh", {
    mode: "cors",
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  }).then((resp) => resp.json());
};

const checkAuthenticationResponse = (resp) => {
  if (resp && resp.message !== undefined) {
    if (
      resp.message === "missing or malformed jwt" ||
      resp.message === "invalid or expired jwt"
    ) {
      return false;
    }
  }
  return true;
};
