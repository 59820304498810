import React from "react";

function Row({ data }) {
  return (
    <tr className="transaction-row">
      <td>{data.name}</td>
      <td align="right">{data.totalTransactions}</td>
    </tr>
  );
}

export default Row;
