import { useContext, useCallback, useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Context } from "../../Store";
import { TriliaAppMode, TriliaAppDatabase } from "../../Config";
import { useApiCall } from "../../useApiCall";
import Spinner from "../../Spinner";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import "./dashboard.css";
import Filters from "../filter/Filters";

// https://github.com/szepeshazi/print-elements
import PrinteElements from "../print_elements";
import "../print_elements.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mergeStrings = (s1, s2) => s1 + "_@@_" + s2;
const splitStrings = (s) => s.split("_@@_");

const Dashboard = ({ posit = false }) => {
  const [state] = useContext(Context);
  const [data, setData] = useState(null);
  const [header, setHeader] = useState(null);
  const [keys, setKeys] = useState(null);
  const [transactions, setTransactions] = useState(null); // map[panelkey]=numtransactions
  const fetchDashboardData = useApiCall("/dashboard", "POST");
  const printTarget = useRef(null);

  const fetchDashboardDataCallback = useCallback(
    (body, endpoint_subpath, init) => {
      return fetchDashboardData(body, endpoint_subpath, init);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  useEffect(() => {
    const abort = new AbortController();

    setHeader(null);
    setData(null);
    setKeys(null);
    setTransactions(null);

    fetchDashboardDataCallback(
      JSON.stringify({
        ...state.filters,
        user: { userIds: state.userIds },
        database: TriliaAppMode ? TriliaAppDatabase : state.database,
        // bank: state.bank,
        posit: posit,
      }),
      "",
      { signal: abort.signal },
    ).then((resp) => {
      // console.log(resp);
      if (!resp || !resp.panels) {
        setData(null);
        return;
      }

      // prepare panels map
      let tempkeys = [];
      let panels = new Map();
      let trans = new Map();
      resp.panels.forEach((p) => {
        if (tempkeys.indexOf(p.database) === -1) {
          tempkeys.push(p.database);
        }
        const uniq = mergeStrings(p.title, p.subtitle);
        if (!panels.has(uniq)) {
          panels.set(uniq, []);
        }
        if (!trans.has(uniq)) {
          trans.set(uniq, {
            syncId: p.syncId,
            gross: 0,
            sum: 0,
            panelType: p.panelType,
            labelType: p.labelType,
            valueType: p.valueType,
          });
        }
      });

      // loop all unique panels
      panels.forEach((_, k) => {
        // loop all panels with matching names
        let temp = new Map();
        resp.panels.forEach((p) => {
          const uniq = mergeStrings(p.title, p.subtitle);
          if (uniq === k) {
            const tr = trans.get(uniq);
            trans.set(uniq, {
              ...tr,
              gross: tr.gross + p.gross,
              sum: tr.sum + p.sum,
            });
            if (p.items) {
              p.items.forEach((v) => {
                if (!temp.has(v.point)) {
                  temp.set(v.point, []);
                }
                temp.get(v.point).push(v.value);
              });
            }
          }
        });
        // merge map into a new array
        let merged = [];
        temp.forEach((v, k) => {
          let values = new Map(v.map((a, i) => [tempkeys[i], a]));
          values.set("point", k);
          merged.push(Object.fromEntries(values));
        });
        merged.sort((a, b) => {
          return a.point > b.point ? 1 : -1;
        });
        panels.set(k, merged);
      });

      // console.log(Object.fromEntries(panels));
      // console.log(Object.fromEntries(trans));

      setHeader(resp.header);
      setData(Object.fromEntries(panels));
      setTransactions(Object.fromEntries(trans));
      setKeys(tempkeys);
    });

    return () => abort.abort();
  }, [
    state.access_token,
    setData,
    setTransactions,
    setKeys,
    state.database,
    state.bank,
    state.filters,
    state.filters.date.range,
    state.filters.date.custom.start,
    state.filters.date.custom.end,
    state.userIds,
    posit,
    fetchDashboardDataCallback,
  ]);

  // if (!state.is_admin) {
  //     return <Navigate to="/tr/total" />
  // }

  return (
    <Container fluid>
      <h2 className="page-header">Με μια ματιά...</h2>

      <Filters
        datesVisible={true}
        devicesVisible={false}
        locationVisible={false}
        transactionsVisible={false}
        autoClose={true}
      />

      {!data && <Spinner />}

      {data && (
        <div className="dashboard-container" ref={printTarget}>
          <DashboardHeader header={header} printTarget={printTarget} />
          <DashboardPanels
            data={data}
            keys={keys}
            transactions={transactions}
          />
        </div>
      )}
    </Container>
  );
};

const PrintButton = ({ printTarget }) => {
  return (
    <Button
      className="pe-no-print print-button"
      size="sm"
      onClick={() => {
        PrinteElements.print([printTarget.current]);
      }}
    >
      <FontAwesomeIcon icon="print" /> Εκτύπωση
    </Button>
  );
};

const DashboardHeader = ({ header, printTarget }) => {
  // <div className="section">
  //     <span className="title">Συσκευές</span>
  //     <span className="value">{header?.totals?.devices}</span>
  // </div>
  return (
    <>
      <div className="dashboard-header">
        <div className="section">
          <span className="title">Συναλλαγές</span>
          <span className="value">{header?.totals?.transactions}</span>
        </div>
        <div className="section">
          <span className="title">Τζίρος</span>
          <span className="value">
            {header?.totals?.gross.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        </div>
        <div className="section">
          <span className="title">Προμήθειες</span>
          <span className="value">
            {header?.totals?.commission.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        </div>
        <div className="section grow" />
        <PrintButton printTarget={printTarget} />
      </div>
    </>
  );
};

const DashboardPanels = ({ data, keys, transactions }) => {
  return (
    <Row>
      {data &&
        Object.keys(data).map((panel) => (
          <Col className="panel-span" md={4} key={panel}>
            <div className="dashboard-panel">
              <h5 className="dashboard-panel-title">
                <span className="dashboard-panel-title-left">
                  {splitStrings(panel)[0]}
                </span>
              </h5>
              {transactions && transactions[panel].panelType === "area" && (
                <PanelAreaChart
                  data={data}
                  keys={keys}
                  panel={panel}
                  transactions={transactions}
                />
              )}
              {transactions && transactions[panel].panelType === "bar" && (
                <PanelBarChart
                  data={data}
                  keys={keys}
                  panel={panel}
                  transactions={transactions}
                />
              )}
            </div>
          </Col>
        ))}
    </Row>
  );
};

const formatChartLegendLabel = (value) => {
  return value.toLocaleUpperCase();
};

const formatYAxisLabel = (value) => {
  return value.toLocaleString();
};

const formatChartTooltipValue = (value, name) => {
  return [`${value.toLocaleString()}`, name.toLocaleUpperCase()];
};

const formatChartCurrencyTooltipValue = (value, name) => {
  return [
    `${value.toLocaleString(undefined, { style: "currency", currency: "EUR" })}`,
    name.toLocaleUpperCase(),
  ];
};

const formatTooltipLabel = (value) => {
  return new Date(value).toLocaleDateString("el-GR");
};

const formatValueByType = (typ, value, name) => {
  switch (typ) {
    case "number":
      return formatChartCurrencyTooltipValue(value, name);
    default:
      return formatChartTooltipValue(value, name);
  }
};

// const colors = ["#ffc658", "#82ca9d", "#8884d8"];
const colors = [
  "#e56a54",
  "#202a44",
  "#ffc658",
  "#82ca9d",
  "#8884d8",
  "#884448",
  "#a4de6c",
  "#8dd1e1",
  "#83a6ed",
];

const PanelAreaChart = ({ data, keys, panel, transactions }) => {
  return (
    <div
      style={{ position: "relative", width: "100%", paddingBottom: "200px" }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <ResponsiveContainer>
          <AreaChart
            className="area-chart"
            data={data[panel]}
            margin={{ top: 10, right: 0, bottom: 0, left: 20 }}
            syncId={transactions[panel].syncId}
          >
            <XAxis dataKey="point" overlapping="stagger" />
            <YAxis tickFormatter={formatYAxisLabel} />
            <CartesianGrid width="80%" stroke="#ddd" strokeDasharray="5 5" />
            {keys &&
              keys.map((v, i) => {
                return (
                  <Area
                    key={v}
                    type="monotone"
                    dataKey={v}
                    stroke={colors[i % colors.length]}
                    fill={colors[i % colors.length]}
                    dot={null}
                    stackId="1"
                  />
                );
              })}
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              formatter={formatChartLegendLabel}
            />
            <Tooltip
              formatter={(value, name) =>
                formatValueByType(transactions[panel].valueType, value, name)
              }
              labelFormatter={formatTooltipLabel}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const PanelBarChart = ({ data, keys, panel, transactions }) => {
  return (
    <div
      style={{ position: "relative", width: "100%", paddingBottom: "200px" }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <ResponsiveContainer>
          <BarChart
            className="bar-chart"
            data={data[panel]}
            margin={{ top: 10, right: 0, bottom: 0, left: 20 }}
            syncId={transactions[panel].syncId}
          >
            <XAxis dataKey="point" overlapping="stagger" />
            <YAxis tickFormatter={formatYAxisLabel} />
            <CartesianGrid stroke="#ddd" strokeDasharray="5 5" />
            <Tooltip
              formatter={(value, name) =>
                formatValueByType(transactions[panel].valueType, value, name)
              }
            />
            {keys &&
              keys.map((v, i) => {
                return (
                  <Bar
                    key={v}
                    type="monotone"
                    dataKey={v}
                    stroke={colors[i % colors.length]}
                    fill={colors[i % colors.length]}
                    dot={null}
                  />
                );
              })}
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              formatter={formatChartLegendLabel}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;
