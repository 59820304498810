import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "react-oidc-context";
// import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../src/style.scss";
// import "./index.css";
import { config } from "./Config";
import App from "./App";

const authConfig = {
  authority: config.OIDCIssuer,
  client_id: config.OIDCClientId,
    client_secret: config.OIDCClientSecret ? config.OIDCClientSecret : undefined,
    disablePKCE: config.OIDCClientSecret ? true : false,
  redirect_uri: config.OIDCRedirect,
  loadUserInfo: true,
  scope: "openid email groups",
  metadataSeed: config.OIDCEndSession ? {
    end_session_endpoint: config.OIDCEndSession,
  } : undefined,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTimeInSeconds: 30,
};

const onSigninCallback = (user): void => {
  // console.log("SIGNIN: ", user.access_token)
  window.history.replaceState(
    {},
    document.title,
    "/"
  );
  window.location.pathname = "/";
};

const onSignoutCallback = (data): void=>{
  // console.log("SIGNOUT")
};

ReactDOM.render(
  <StrictMode>
    <AuthProvider  {...authConfig} onSigninCallback={onSigninCallback} onSignoutCallback={onSignoutCallback}>
    <App />
    </AuthProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
