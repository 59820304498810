import jwt_decode from "jwt-decode";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
const yesterdayString = yesterday.toISOString().split("T")[0];

export const DefaultFilters = () => {
  return {
    devices: {
      active: true,
      inactive: true,
    },
    location: {
      city: "",
      area: "",
      nomos: "",
      zipCode: "",
      op: "and",
    },
    date: {
      range: 1, // range: 0=custom, 1=yesterday, 2=thisweek, 3=prevweek, 4=thismonth, 5=prevmonth
      custom: {
        start: yesterdayString,
        end: yesterdayString,
      },
    },
    transactions: {
      min: 1,
      max: -1,
    },
  };
};

export const DefaultState = () => {
  const token = sessionStorage.getItem("access_token");
  const decoded = token
    ? jwt_decode(token)
    : { name: null, admin: false, dealer: false };
  return {
    theme: "light",
    user: decoded?.name,
    groups: decoded?.groups,
    is_admin: decoded?.admin,
    is_dealer: decoded?.dealer,
    access_token: token,
    error: null,
    presetDates: [{ start: yesterdayString, end: yesterdayString }],
    limits: {},
    areas: [],
    cities: [],
    nomoi: [],
    zipcodes: [],
    salesmen: [],
    filters: DefaultFilters(),
    searchText: "",
    userIds: JSON.parse(sessionStorage.getItem("userids")) || [], // salesmen uuids
    database: sessionStorage.getItem("database") || "",
    availableDatabases: [],
    availableCategories: [],
    availableBanks: [],
    bank: sessionStorage.getItem("bank") || "",
    alerts: [
      // {
      //     id: <optional>, // anything other than 0|null|undefined|false, used when calling DELETE_ALERT
      //     title: "Hello world",
      //     message: "Lorem ipsum",
      //     color: "success",
      //     timeout: 2000, // milliseconds
      // },
    ],
    pageSize: sessionStorage.getItem("page_size") || 15, // tables pagination
    downloads: {},
  };
};
