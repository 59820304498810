import React from "react";

function Header() {
  return (
    <thead>
      <tr className="transaction-row">
        <th>Κατηγορία</th>
        <th>Συναλλαγές</th>
      </tr>
    </thead>
  );
}

export default Header;
