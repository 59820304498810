import { Version } from "./version";

// NOTE
//
// environment variable picked up by react *must* be named as REACT_APP_xxx !!!
// and is only picked up during "yarn build" or "yarn start"

export const TriliaAppMode = process.env.REACT_APP_TRILIA_APP === "1";
export const TriliaAppDatabase = process.env.REACT_APP_TRILIA_DB || "trilia";
export const TriliaAppDownloadURL =
  process.env.REACT_APP_TRILIA_DOWNLOAD_URL ||
  "https://setup.triliaspot.com/updated/";

const prod = {
  VERSION: Version,
  // API_URL: "https://21.126.10.140/api/v1",
  // WS_URL: "wss://20.126.10.140/api/v1/ws",
  // API_URL: process.env.REACT_APP_API_URL, // "http://l4a-api.rnd.matdev.gr/api/v1",
  // WS_URL: "ws://l4a-api.rnd.matdev.gr/api/v1/ws",
  API_VERSION: "v1",
  API_URL: "/api/v1",
  OIDCIssuer: process.env.REACT_APP_OIDC_ISSUER || "https://auth.mis.trilia.gr",
  OIDCRedirect:
    process.env.REACT_APP_OIDC_REDIRECT ||
    "https://merchants.mis.trilia.gr/callback",
  OIDCEndSession:
    process.env.REACT_APP_OIDC_END_SESSION ||
    "https://auth.mis.trilia.gr/logout?rd=https://merchants.mis.trilia.gr",
};

const stage = {
  VERSION: Version,
  API_VERSION: "v1",
  API_URL: "/api/v1",
  OIDCIssuer: process.env.REACT_APP_OIDC_ISSUER || "https://auth.mis.trilia.gr",
  OIDCRedirect:
    process.env.REACT_APP_OIDC_REDIRECT ||
    "https://merchants-stage.mis.trilia.gr/callback",
  OIDCEndSession:
    process.env.REACT_APP_OIDC_END_SESSION ||
    "https://auth.mis.trilia.gr/logout?rd=https://merchants-stage.mis.trilia.gr",
};

const dev = {
  VERSION: "dev",
  API_VERSION: "v1",
  API_URL: "http://localhost:8888/api/v1",
  WS_HOST: "localhost:8888",
  OIDCIssuer:
    process.env.REACT_APP_OIDC_ISSUER || "https://auth-l4a.hq.trilia.gr",
  OIDCRedirect:
    process.env.REACT_APP_OIDC_REDIRECT || "http://localhost:3000/callback",
  OIDCEndSession:
    process.env.REACT_APP_OIDC_END_SESSION ||
    "https://auth-l4a.hq.trilia.gr/logout?rd=http://localhost:3000",
};

export let config = dev;
switch (process.env.NODE_ENV) {
  case "staging":
  case "stage":
    config = stage;
    break;
  case "production":
  case "prod":
    config = prod;
    break;
  default:
    break;
}
config.OIDCClientId =
  process.env.REACT_APP_OIDC_CLIENT_ID || "4d2c2c4e39a547919323f2a7f791d87a";
config.OIDCClientSecret = process.env.REACT_APP_OIDC_CLIENT_SECRET;

console.log(`environment is ${process.env.NODE_ENV}`);
